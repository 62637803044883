const statusManagementKpi = [
    {
        name: 'Cần nhập thành phẩm',
        value: 0,
    },
    {
        name: 'Cần chấm',
        value: 1,
    },
    {
        name: 'Đã chấm',
        value: 2,
    },
]

const pointOptionKpi = [
    {
        name: '0%',
        value: 0,
    },
    {
        name: '30%',
        value: 30,
    },
    {
        name: '60%',
        value: 60,
    },
    {
        name: '90%',
        value: 90,
    },
    {
        name: '100%',
        value: 100,
    },
]

const resultPointWeekly = [
    {
        name: 'Không đạt',
        value: 0,
    },
    {
        name: 'Đạt',
        value: 1,
    },
]

export {
    pointOptionKpi,
    resultPointWeekly,
    statusManagementKpi,
}